<template>
<div class="dashboard-widget-tabs mb-0 p-0 dashboard-header-boxed-calender">

    <div class="dashboard-widget-tabs-content mb-0 p-15">
        <h2>
            <!-- <i class="uil uil-calendar-alt"></i> -->
            <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path class="fa-primary" d="M160 64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V192H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64z" />
                <path class="fa-secondary" d="M0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM208 256C199.2 256 192 263.2 192 272V304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208zM320 304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336C327.2 256 320 263.2 320 272V304zM80 384C71.16 384 64 391.2 64 400V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80zM192 432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208C199.2 384 192 391.2 192 400V432zM336 384C327.2 384 320 391.2 320 400V432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336z" /></svg>
            <span class="page-title-svg">تقویم اقتصادی</span>
        </h2>
        <div class="dashboard-widget-h-btns">
            <!-- <a href="" @click.prevent="open_add_event_modal()" class="m-left m-top tgju-btn tgju-btn-primary show-m" v-tooltip="'افزودن رویداد'"><i class="uil uil-plus-circle"></i> <span class="hide-m-min">افزودن رویداد</span></a>
            <a href="" @click.prevent="showSidebar('events')" class="m-left m-top tgju-btn tgju-btn-light show-m" v-tooltip="'رویدادها'"><i class="uil uil-calendar-alt "></i> <span class="hide-m">رویدادها</span></a>
            <a href="" @click.prevent="showSidebar('tags')" class="m-left m-top tgju-btn tgju-btn-light show-m" v-tooltip="'تقویم‌ها'"><i class="uil uil-calender"></i> <span class="hide-m">تقویم‌ها</span></a> -->
            <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light " v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
        </div>
    </div>

    <div class="widget-tabs widget-calender-btns d-flex-m" style="padding: 5px 15px 12px; border-bottom: 1px solid rgb(230, 230, 230);">
        <div class="widget-tabs-nav">
            <a href="" @click.prevent="open_add_event_modal()" class="m-left m-top tgju-btn tgju-btn-primary flex-grow-1-370" v-tooltip="'افزودن رویداد'"><i class="uil uil-plus-circle"></i> <span>افزودن رویداد</span></a>
            <a href="" @click.prevent="showSidebar('events')" class="m-left m-top tgju-btn tgju-btn-light" v-tooltip="'رویدادها'"><i class="uil uil-calendar-alt"></i> <span class="hide-320">رویدادها</span></a>
        </div>
        <div class="widget-tabs-btn">
            <a href="" @click.prevent="showSidebar('tags')" class="m-top tgju-btn tgju-btn-light" v-tooltip="'تقویم‌ها'"><i class="uil uil-calender"></i> <span class="hide-320">تقویم‌ها</span></a>
        </div>
    </div>
    <div class="sidebar-box">
        <slot v-if="widgetLoad">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <slot v-else>
            <FullCalendar ref="fullCalendar" :options="calendarOptions" id="calendar"></FullCalendar>
            <div v-if="sidebarShow == true" class="c-sidebar " :class="sidebarOptions == 'other' || sidebarOptions == 'tags' ? 'c-sidebar-left': ''">
                <div v-if="sidebarOptions == 'events'" class="events-options">
                    <div class="sidebar-title-row">
                        <h3><i class="uil uil-calendar-alt "></i> رویدادها</h3>
                        <i class="uil uil-times pointer" @click.prevent="hideSidebar()"></i>
                    </div>
                    <div class="widget-select w-100 m-top calendar-tags">
                        <v-select2 v-model="calendarsSelectTags" :clearable="false" label="title" :reduce="(option) => option.id" :options="labelsSelects" @input="calendarsFilter()">
                        </v-select2>
                    </div>
                    <div class="widget-select w-100 m-top calendar-time">
                        <v-select2 v-model="eventsView" :clearable="false" label="value" :reduce="(option) => option.key" :options="[{key: 'day', value: 'بازه زمانی : روز'},{key: 'week', value: 'بازه زمانی : هفته'},{key: 'month', value: 'بازه زمانی : ماه'}]" @input="eventsFilter()">
                        </v-select2>
                    </div>
                    <input v-if="events.length" class="widget-input m-top w-100" type="text" placeholder="جستجو در رویداد ..." @keyup="eventsFilter()" v-model="eventsFilterInput">
                    <div class="list-widget list-dash-items list-widget-structure">
                        <template v-for="event in listEvents">
                            <div class="list-row hover bold" :key="event.id" v-if="event.label_id != -1 && !event.hide">
                                <div class="list-col l-title l-title-widget-structure">
                                    <span><i class="fa fa-circle-o list-event-icon default-event" :style="'color: ' + event.color"></i> {{ event.title }}</span>
                                </div>
                                <div v-if="event.label_id != 'Economy'" class="list-col l-item pointer" @click="goEventEdit(event.id)">
                                    <i class="uil uil-edit-alt"></i>
                                </div>
                                <div v-if="event.label_id != 'Economy'" class="list-col l-item red-color pointer" @click="removeEvent(event.id)">
                                    <i class="uil uil-trash remove-row"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="sidebarOptions == 'tags'" class="tags-options">
                    <div class="sidebar-title-row">
                        <h3><i class="uil uil-calender"></i> تقویم‌ها</h3>
                        <i class="uil uil-times pointer" @click.prevent="hideSidebar()"></i>
                    </div>
                    <div class="list-widget list-dash-items list-widget-structure">
                        <div class="list-row no-filter hover bold">
                            <div class="list-col l-title l-title-widget-structure">
                                <span><i class="fa fa-circle list-event-icon" :style="'color: #3788d8;'"></i> تقویم عمومی</span>
                            </div>
                            <label class="list-col l-item list-col-checkbox">
                                <input type="checkbox" v-model="allowEvents" :value="0" @change="loadData()">
                            </label>
                            <div class="list-col l-item list-col-inactive">
                                <i class="uil uil-edit-alt"></i>
                            </div>
                            <div class="list-col l-item red-color list-col-inactive">
                                <i class="uil uil-trash remove-row"></i>
                            </div>
                        </div>
                        <div class="list-row no-filter hover bold">
                            <div class="list-col l-title l-title-widget-structure">
                                <span><i class="fa fa-circle list-event-icon" :style="'color: #000000;'"></i> تقویم اقتصادی</span>
                            </div>
                            <label class="list-col l-item list-col-checkbox">
                                <input type="checkbox" v-model="allowEvents" value="Economy" @change="loadData()">
                            </label>
                            <div class="list-col l-item list-col-inactive">
                                <i class="uil uil-edit-alt"></i>
                            </div>
                            <div class="list-col l-item red-color list-col-inactive">
                                <i class="uil uil-trash remove-row"></i>
                            </div>
                        </div>
                        <div v-for="label in labels" :key="label.id" class="list-row no-filter hover bold">
                            <div class="list-col l-title l-title-widget-structure">
                                <span><i class="fa fa-circle list-event-icon" :style="'color: ' + label.color + ';'"></i> {{ label.title }}</span>
                            </div>
                            <label class="list-col l-item list-col-checkbox">
                                <input type="checkbox" v-model="allowEvents" :value="label.id" @change="loadData()">
                            </label>
                            <div class="list-col l-item pointer" @click="goEditLabel(label.id)">
                                <i class="uil uil-edit-alt"></i>
                            </div>
                            <div class="list-col l-item red-color pointer" @click="removeLabel(label.id)">
                                <i class="uil uil-trash remove-row"></i>
                            </div>
                        </div>
                        <div :class="'list-row no-filter hover bold ' + (addEditLoading ? 'loading-active' : '') ">
                            <v-swatches v-model="label.color" popover-x="left" swatches="text-advanced" :trigger-style="{ width: '28px', height: '28px' }"></v-swatches>
                            <div class="list-col l-title l-title-widget-structure " style="position: relative;">
                                <input :class="'list-widget-event-add ' + (labelEditId ? 'list-edit-mode' : '') " type="text" placeholder="افزودن تقویم‌ جدید" v-model="label.title" @keyup.enter="addLabel()" ref="event-add-input">
                                <div class="list-col l-item red-green pointer" v-if="labelEditId" @click="addLabel()" ref="event-add-input">
                                    <i class="uil uil-check"></i>
                                </div>
                                <div class="list-col l-item red-color pointer" v-if="labelEditId" @click="cancelEdit()">
                                    <i class="uil uil-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="sidebarOptions == 'other'" class="other-options">

<div class="sidebar-title-row">

<h3><i class="uil uil-setting"></i> تنظیمات نمایش تقویم</h3>

<i class="uil uil-times pointer" @click.prevent="hideSidebar()"></i>

</div>

<div class="list-widget list-dash-items list-widget-structure">

<div class="list-row no-filter hover bold">

<div class="list-col l-title l-title-widget-structure">

<label class="w100 calendar-check-box">

<input type="checkbox" v-model="allowEvents" :value="0"  @change="loadData()">

<span>تقویم عمومی</span>

<div class="calendar-color" style="background:rgb(55, 136, 216);"></div>

</label>

</div>

</div>

<div class="list-row no-filter hover bold">

<div class="list-col l-title l-title-widget-structure">

<label class="w100 calendar-check-box">

<input type="checkbox" v-model="allowEvents" value="Economy" @change="loadData()">

<span>تقویم اقتصادی</span>

<div class="calendar-color"></div>

</label>

</div>

</div>

<div v-for="label in labels" :key="label.id" class="list-row no-filter hover bold">

<div class="list-col l-title l-title-widget-structure">

<label class="w100 calendar-check-box">

<input type="checkbox" v-model="allowEvents" :value="label.id" @change="loadData()">

<span>{{label.title}}</span>

<div class="calendar-color" :style="'background: '+label.color+';'"></div>

</label>

</div>

</div>

</div>

</div> -->
            </div>
            <div v-if="sidebarShow == true" @click.prevent="hideSidebar()" class="calendar-sidebar-bg"></div>
        </slot>
    </div>

</div>
</template>

<style lang="scss" scoped>
.vue-swatches {
    border-left: 1px solid #e6e6e6;
    padding: 6px 7px;
    height: 40px;
}

.default-event {
    color: #3788d8;
}

.fc .fc-scrollgrid-section-liquid {
    height: 2px !important;
}

.cancel-edit {
    position: absolute;
    top: 14px;
    left: 15px;
    font-size: 14px;
}

.sidebar-box {
    height: calc(100% - 138.5px);
}
</style>

<script>
import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css"
import moment from "moment-jalaali";
import FullCalendar, { collectFromHash } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import faLocale from "@fullcalendar/core/locales/fa";
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'CalendarView',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        FullCalendar,
        VSwatches
    },
    data() {
        return {
            sidebarOptions: 'events',
            calendarsSelectTags: {
                id: 0,
                title: 'تقویم عمومی',
                color: '',
            },
            sidebarShow: false,
            events: [],
            listEvents: [],
            labels: [],
            label: {
                id: 0,
                title: '',
                color: '#3788d8',
            },
            labelsSelects: [],
            allowEvents: [],
            allowEconomyEvents: false,
            eventsView: 'month',
            colors: ["#d50000", "#0b8043", "#f6bf26", "#8e24aa", "#f4511e", "#039be5", "#666", "#ff51bb", "#18ccd6", "#795548", ""],
            labelEditId: false,
            eventsFilterInput: '',
            calendarOptions: {
                headerToolbar: {
                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                    center: 'title',
                    left: 'prev,next today',
                },
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                initialView: 'dayGridMonth',
                locale: faLocale,
                selectable: true,
                selectHelper: true,
                lang: 'fa',
                isJalaali: true,
                isRTL: true,
                editable: true,
                droppable: true,
                draggable: true,
                eventResizableFromStart: true,
                eventDurationEditable: true,
                displayEventTime: false,
                eventLimit: true,
                eventClick: (calEvent, jsEvent, view) => {
                    this.goEventEdit(calEvent.event.id);
                },
                events: [],
            },
            responsive: {
                rightSection: true,
                leftSection: false,
            },
            widgetLoad: true,
            addEditLoading: false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        loadData() {
            let labels = [];
            this.data.labels.forEach(event => {
                labels.push({
                    id: event.id,
                    title: event.title,
                    color: event.color,
                });
            });
            this.labels = labels;

            let labelsSelects = [{
                id: 0,
                title: 'تقویم عمومی',
                color: '',
            }];
            // برای بررسی موجود بودن تقویم اقتصادی در سلکت شده ها
            if (this.allowEvents.includes('Economy')) {
                labelsSelects.push({
                    id: 'Economy',
                    title: ' تقویم اقتصادی',
                    color: '',
                });
            }
            // تغیین تقویم عمومی به پیشفرض در صورت فعال نبودن تقویم اقتصادی
            if (!this.allowEvents.includes('Economy')) {
                this.calendarsSelectTags = {
                    id: 0,
                    title: 'تقویم عمومی',
                    color: '',
                };
            }
            // افزودن لیست ایونت جدید مجزا از ایونت اصلی برای سایدبار رویداد ها
            this.data.labels.forEach(labelsSelect => {
                labelsSelects.push(labelsSelect);
            });
            this.labelsSelects = labelsSelects;

            if (this.widgetLoad) {
                let labelsDefaultSet = [];
                this.labels.forEach(event => {
                    labelsDefaultSet.push(event.id);
                });
                labelsDefaultSet.push(0);
                this.allowEvents = labelsDefaultSet;
            }

            let events = [];
            let listEvents = [];
            // تابع افزودن ایوینت ها به  متغیرهای آرایه مربوط به آنها
            this.data.events.forEach(event => {
                if (this.allowEvents.includes(event.label_id)) {
                    events.push({
                        id: event.id,
                        title: event.title,
                        description: event.description,
                        start: event.start,
                        end: event.end,
                        color: this.eventColor(event.label_id),
                        label_id: event.label_id,
                        editable: event.editable,
                        hide: false,
                    });
                }
                listEvents.push({
                    id: event.id,
                    title: event.title,
                    description: event.description,
                    start: event.start,
                    end: event.end,
                    color: this.eventColor(event.label_id),
                    label_id: event.label_id,
                    editable: event.editable,
                    hide: false,
                });
            });

            // بررسی خوردن تیک تقویم اقتصادی و افزودن مقادیر به متغیر آرایه ایوینت اصلی
            if (this.allowEvents.includes('Economy')) {
                this.data.economy_events.forEach(event => {
                    events.push({
                        id: event.id,
                        title: event.title,
                        description: event.description,
                        start: event.start,
                        end: event.end,
                        color: this.eventColor(event.label_id),
                        label_id: 'Economy',
                        editable: event.editable,
                        hide: false,
                    });
                });
            }

            // بررسی خوردن تیک تقویم اقتصادی و افزودن مقادیر به متغیر آرایه ایوینت لیست
            if (this.allowEvents.includes('Economy')) {
                this.data.economy_events.forEach(event => {
                    listEvents.push({
                        id: event.id,
                        title: event.title,
                        description: event.description,
                        start: event.start,
                        end: event.end,
                        color: this.eventColor(event.label_id),
                        label_id: 'Economy',
                        editable: event.editable,
                        hide: false,
                    });
                });
            }

            this.calendarOptions.events = events;
            this.events = events;
            this.listEvents = listEvents;

            this.widgetLoad = false;
            this.eventsFilter(true);
        },
        // مودال تنظیمات رویداد
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id;

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Calendar.vue',
                config: {
                    data: {
                        title: this.widget.title,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$helpers.setEvent(callbackEvent).then((status) => {
                if (!status) {
                    return;
                }

                this.$root.$on(callbackEvent, (settings) => {
                    this.widget.title = settings.title;
                    let widget = this.$helpers.unbindObject(this.widget);
                    this.$helpers.saveWidget(widget);
                });
            });
        },
        // مودال افزودن رویداد
        open_add_event_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_addEvent';

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'addCalendarEvent.vue',
                config: {
                    title: 'افزودن رویداد',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: true,
                    data: {
                        widget: this.widget,
                        labels: this.labels,
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$helpers.setEvent(callbackEvent).then((status) => {
                if (!status) {
                    return;
                }

                this.$root.$on(callbackEvent, (response) => {
                    if (response.action == 'addEvent') {
                        let event = {
                            id: response.data.event.id,
                            title: response.data.event.title,
                            description: response.data.event.description,
                            start: moment(response.data.event.start, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm'),
                            end: moment(response.data.event.end, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm'),
                            color: this.eventColor(response.data.event.label_id),
                            label_id: response.data.event.label_id,
                            editable: true,
                            hide: false,
                        };

                        this.calendarOptions.events.push(event);
                        this.eventsFilter();

                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: 'رویداد با موفقیت ایجاد شد',
                        });
                    }
                });
            });
        },
        // باز شدن مودال ویرایش رویداد
        open_edit_event_modal(event) {
            let callbackEvent = this.$router.currentRoute.name + '_editEvent';

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'addCalendarEvent.vue',
                config: {
                    title: 'ویرایش رویداد',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        widget: this.widget,
                        labels: this.labels,
                        event
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$helpers.setEvent(callbackEvent).then((status) => {
                if (!status) {
                    return;
                }

                this.$root.$on(callbackEvent, (response) => {
                    if (response.action === 'editEvent') {
                        let index = this.$helpers.getIndex(this.events, response.data.event.id);

                        let event = this.events[index];

                        event.title = response.data.event.title;
                        event.description = response.data.event.description;
                        event.start = moment(response.data.event.start, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm');
                        event.end = moment(response.data.event.end, 'jYYYY/jMM/jDD HH:mm').format('YYYY-MM-DD HH:mm');
                        event.color = this.eventColor(response.data.event.label_id);
                        event.label_id = response.data.event.label_id;

                        this.eventsFilter();
                    } else if (response.action === 'removeEvent') {
                        this.removeEvent(response.data.event.id);
                    }
                });
            });
        },
        // حذف یک رویداد
        removeEvent(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.events, id);

                    this.events.splice(index, 1);
                    this.calendarOptions.events.splice(index, 1);

                    this.listEvents.splice(index, 1);
                    // TODO: خط زیر باید کامنت بشه تا حذف کردن درست کار کنه دلیل باید توجیه شود
                    // this.calendarOptions.listEvents.splice(index, 1);

                    this.$helpers.makeRequest('DELETE', '/calendar/event-delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "رویداد با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف رویداد رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        addLabel() {
            if (!this.label.title) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان تقویم را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }

            if (!this.label.title) {
                return;
            }

            if (this.labelEditId) {
                this.editLabel();
                return;
            }

            this.addEditLoading = true;
            let api_data = {
                title: this.label.title,
                color: this.label.color,
            };

            this.$helpers.makeRequest('POST', '/calendar/store-label', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    this.label.id = api_response.data.response.id;
                    this.labels.push(this.$helpers.unbindObject(this.label));
                    this.label.id = null;
                    this.label.title = '';
                    this.label.color = '';
                }
                this.addEditLoading = false;
            });
        },
        // حذف تقویم
        removeLabel(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.labels, id);

                    this.labels.splice(index, 1);

                    this.$helpers.makeRequest('DELETE', '/calendar/label-delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            //
                        }
                    });
                }
            });
        },

        goEditLabel(id) {
            let index = this.$helpers.getIndex(this.labels, id);
            let label = this.labels[index];

            this.labelEditId = label.id;
            this.label.title = label.title;
            this.label.color = label.color;

            this.$refs['event-add-input'][0].focus();
        },
        // ویرایش تقویم
        editLabel() {
            let index = this.$helpers.getIndex(this.labels, this.labelEditId);
            let label = this.labels[index];
            this.addEditLoading = true;

            let api_data = {
                title: this.label.title,
                color: this.label.color,
            };

            this.$helpers.makeRequest('POST', '/calendar/edit-label/' + this.labelEditId, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.labelEditId = false;
                    label.title = this.label.title;
                    label.color = this.label.color;

                    this.label.title = '';
                    this.label.color = '';
                }
                this.addEditLoading = false;
            });
        },
        // کنسل کردن ویرایش تقویم
        cancelEdit() {
            this.labelEditId = false;
            this.label.title = '';
            this.label.color = '';
        },
        // انتخاب رنگ تقویم
        eventColor(id) {
            let index = this.$helpers.getIndex(this.labels, id);
            let label = this.labels[index];

            let color = '';
            if (typeof label != 'undefined') {
                color = label.color;
            }

            if (id == -1) {
                color = "#000";
            }

            return color;
        },
        goEventEdit(id) {
            let index = this.$helpers.getIndex(this.events, id);
            let event = this.$helpers.unbindObject(this.events[index]);
            event.start = moment(event.start).format('jYYYY/jMM/jDD HH:mm');
            event.end = moment(event.end).format('jYYYY/jMM/jDD HH:mm');
            this.open_edit_event_modal(event);
        },
        // فیلتر کردن و جستجوی رویداد ها
        eventsFilter(firtLoad) {
            this.listEvents.forEach(event => {
                let cid = 0;
                if (firtLoad) {
                    cid = this.calendarsSelectTags.id
                } else {
                    cid = this.calendarsSelectTags
                }
                if (event.label_id == cid) {
                    //-----------
                    let a = moment(new Date()).format('MM');
                    let b = moment(this.$refs.fullCalendar.$options.calendar.currentData.currentDate).format('MM');
                    //-----------
                    if (a === b) {
                        event.hide = true;

                        if (this.eventsView == 'day') {
                            let currentDate = moment(moment(new Date()).format('YYYY-MM-DD'));

                            let startDate = moment(event.start).format('YYYY-MM-DD');

                            let endDate = moment(event.end).format('YYYY-MM-DD');

                            if (currentDate.isBetween(startDate, endDate, 'days', true)) {
                                event.hide = false;
                                return;
                            }
                        }

                        if (
                            event.title.indexOf(this.eventsFilterInput.trim()) > -1 &&
                            (moment(event.start).isSame(new Date(), this.eventsView) ||
                                moment(event.end).isSame(new Date(), this.eventsView))
                        ) {
                            event.hide = false;
                        }
                    } else {
                        event.hide = true;

                        let cu_du = this.$refs.fullCalendar.$options.calendar.currentData.currentDate;

                        let startDate = moment(cu_du).startOf('months').format('YYYY-MM-DD');

                        let endDate = moment(cu_du).endOf('months').format('YYYY-MM-DD');

                        let eventstart = moment(event.start, "YYYY-MM-DD");

                        if (event.title.indexOf(this.eventsFilterInput.trim()) > -1 && eventstart.isBetween(startDate, endDate, 'days', true)) {
                            event.hide = false;
                        }
                    }
                } else {
                    event.hide = true
                }
            });
        },
        // فیلتر کردن و جستجوی تقویم ها
        calendarsFilter() {
            // if(this.calendarsSelectTags == 'Economy'){
            // 	this.data.economy_events.forEach(event => {
            // 		this.listEvents.push({
            // 		id: event.id,
            // 		title: event.title,
            // 		description: event.description,
            // 		start: event.start,
            // 		end: event.end,
            // 		color: this.eventColor(event.label_id),
            // 		label_id: 'Economy',
            // 		editable: event.editable,
            // 		hide: false,
            // 		});
            // 	});
            // }

            this.listEvents.forEach(event => {
                if (event.label_id == this.calendarsSelectTags) {
                    event.hide = false
                } else {
                    event.hide = true
                }
            });
        },
        // نمایش سایدبار کناری
        showSidebar(e) {
            if (this.sidebarOptions == e) {
                this.sidebarShow = false;
                this.sidebarOptions = null;
            } else {
                this.sidebarOptions = e;
                this.sidebarShow = true;
            }
        },
        // مخفی سازی سایدبار کناری
        hideSidebar() {
            this.sidebarShow = false;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Calendar.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
